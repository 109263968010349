import { createSlice, current } from '@reduxjs/toolkit';
import useTranslateText from 'hooks/useTranslate';

// initial state
const initialState = {
    openItem: ['maisons-list'],
    drawerOpen: true,
    listeMaisons: [
        {
            id: 'maisons-list',
            // title: useTranslateText("maison-list"),
            title: `Liste maisons`,
            type: 'item',
            url: '/maison-list',
            breadcrumbs: false
        }
    ],
    listeGroupes: [
        {
            id: 'groupes-list',
            // title: useTranslateText("maison-list"),
            title: `Liste groupes`,
            type: 'item',
            url: '/groupe-list',
            breadcrumbs: false
        }
    ],
    comparateur: {
        id: 'comparateur',
        title: `Comparateur`,
        type: 'collapse',
        children: []
    }
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        addMenuMaison(state, action){
            const { id, MRNom } = action.payload; 
            const isExist = state.listeMaisons.filter( maison => Number(maison.value) === Number(id))
            if(isExist.length === 0){
                state.listeMaisons.push({
                    id: `maison-${id}`,
                    title: `Maison ${id}`,
                    type: 'item',
                    url: `/maison/${id}`,
                    breadcrumbs: false,
                    clearable: true,
                    redirect: '/maison-list',
                    value: id,
                    obj: 'listeMaisons',
                    tooltip: MRNom
                });
            } 
        },
        addMenuGroupe(state, action){
            const { id, MRGRpNom} = action.payload;
            const isExist = state.listeGroupes.filter( groupe => Number(groupe.value) === Number(id))
            if(isExist.length === 0){
                state.listeGroupes.push({
                    id: `groupe-${id}`,
                    title: `Groupe ${id}`,
                    type: 'item',
                    url: `/groupe/${id}`,
                    breadcrumbs: false,
                    clearable: true,
                    redirect: '/groupe-list',
                    value: id,
                    obj: 'listeGroupes',
                    tooltip: MRGRpNom
                });
            } 
        },
        addComparateur(state, action){
            const strValues = action.payload.slice().sort((a, b) => (Number(a.value) - Number(b.value))).join(',');
            const isExist = state.comparateur.children.filter(mr => mr.value === strValues);
            if(isExist.length === 0){
                const menuComp = {
                    id: `comp-${strValues}`,
                    title: `${strValues}`,
                    type: 'item',
                    url: `/comparateur/${strValues}`,
                    breadcrumbs: false,
                    clearable: true,
                    value: strValues,
                    obj: 'comparateur',
                    redirect: '/maison-list'
                }
                state.comparateur.children.push(menuComp);
            }
        },
        removeMenu(state, action){
            const { key, value } = action.payload;
            if(key !== 'comparateur'){
                state[key] = state[key].filter(item => Number(item.value) !== Number(value));
            }else{
                state[key].children = state[key].children.filter(item => item.value !== value);
            }
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, addMenuMaison, addMenuGroupe, addComparateur, removeMenu } = menu.actions;
