// third-party
import { FormattedMessage } from 'react-intl';
// assets
import { IconBuildingCommunity } from '@tabler/icons';
import { useSelector } from 'store';

const Groupe = () => {

    const { listeGroupes } = useSelector((state) => state.menu);

    const groupe = {
        id: 'pages-group',
        type: 'group',
        children: [
            {
                id: 'groupes',
                title: <FormattedMessage id="groupe"/>,
                type: 'collapse',
                icon: IconBuildingCommunity,
                children: [...listeGroupes]
            }
        ]
    };

    return groupe;
}

export default Groupe;
