import maison from './maison';
import groupe from './groupe';
import map from './map';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [maison, test]
    items: [maison, groupe, map]
};

export default menuItems;
