import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    fieldsMap: {
        PA: {
            adress: '', coordinate: '', transport: 'driving', time: 30, rayon: 0
        },
        Contact: {
            adress: '', coordinate: '', transport: 'driving', time: 30, rayon: 0
        },
        Contact2: {
            adress: '', coordinate: '', transport: 'driving', time: 30, rayon: 0
        }
    },
    filterFieldsMap: {
        groupe: [],
        exclure: false,
        typAcc: [],
        up: false,
        uhr: false,
        ugd: false,
        ash: false,
        parc: false,
        terrasse: false,
        inf: false,
        jardin: false,
        antifugue: false,
        couple: false,
        doubleJ: false,
        actif: [],
        budget: "",
        notesMR: "",
        count: 0
    },
    selectedMR: [],
    filterTransport: []
};

const countFilter = (state) => {
    let count = 0;
    const allFields = [ 'groupe', 'typAcc', 'up', 'uhr', 'ugd', 'ash', 'parc', 'terrasse', 'inf', 'jardin', 'antifugue', 'couple', 'doubleJ', 'actif', 'budget', 'notesMR'];
    const arrFields = ['groupe', 'typAcc', 'actif'];

    Object.keys(state.filterFieldsMap).forEach((item) => {
        if(allFields.includes(item)){
            if(arrFields.includes(item)){
                state.filterFieldsMap[item].length > 0 ? count += 1 : '';
            }else if(item === 'budget' || item === 'notesMR'){
                state.filterFieldsMap[item] !== '' ? count += 1 : ''; 
            }else{
                state.filterFieldsMap[item] === true ? count += 1 : '';
            }
        }
    })

    return count;
}

const map = createSlice({
    name: 'map',
    initialState,
    reducers: {
        saveStateFields(state, action){
            const { key, value } = action.payload;
            state.fieldsMap[key] = value;
        },
        resetFields(state, action){
            const { key, fields } = action.payload;
            fields.forEach((field) =>{
                state.fieldsMap[key][field.name] = field.value
            });
        },
        resetAllFields(state, action){
            state.fieldsMap = {...initialState.fieldsMap}
        },
        saveStateFilterFields(state, action){
            const { key, value } = action.payload;
            state.filterFieldsMap[key] = value;
            if(key === 'couple' && value) state.filterFieldsMap.doubleJ = true;
            const count = countFilter(state);
            state.filterFieldsMap.count = count;
        },
        resetAllFilterFields(state, action){
            state.filterFieldsMap = {...initialState.filterFieldsMap};
        },
        addSelectMR(state, action){
            state.selectedMR.push(action.payload)
        },
        addMultipleSelectMR(state, action){
            state.selectedMR = action.payload;
        },
        removeSelectMR(state, action){
            state.selectedMR = state.selectedMR.filter(item => item.mrId !== action.payload);
        },
        checkedMR(state, action){
            const { mrId, checked } = action.payload;
            const index = state.selectedMR.findIndex(mr => mr.mrId === mrId);
            state.selectedMR[index].checked = checked;
        },
        resetAllSelectMR(state, action){
            state.selectedMR = [];
        },
        addFilterTransport(state,action){
            state.filterTransport.push(action.payload)
        },
        removeFilterTransportByName(state,action){
            const index = state.filterTransport.indexOf(action.payload);
            state.filterTransport.splice(index, 1);
        }
    }
});

export default map.reducer;

export const { addFilterTransport, removeFilterTransportByName,saveStateFields, resetFields, resetAllFields, saveStateFilterFields, resetAllFilterFields, addSelectMR, addMultipleSelectMR, removeSelectMR, checkedMR, resetAllSelectMR } = map.actions; 