// third-party
import { FormattedMessage } from 'react-intl';
// assets
import { IconMap2 } from '@tabler/icons';
import { DASHBOARD_PATH } from 'config';

const Map = () => {

    const map = {
        id: 'carte',
        title: 'Carte',
        type: 'group',
        children: [
            {
                id: 'map',
                title: 'Carte',
                type: 'item',
                icon: IconMap2,
                url: DASHBOARD_PATH
                // children: [...listeMaisons]
            }
        ]
    };

    return map;
}

export default Map;