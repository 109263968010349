// third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

import { persistStore } from 'redux-persist';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

// project imports
import rootReducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

// Constants
const ACTIONS_TO_SYNC = [
  'menu/addMenuMaison',
  'menu/removeMenuMaison',
  'map/saveStateFilterFields',
  'map/resetAllFilterFields'
];

const PERSIST_ACTIONS_BLACKLIST = [
  "persist/PERSIST",
  "persist/REHYDRATE"
];

// Middleware configuration
const stateSyncConfig = {
  blacklist: PERSIST_ACTIONS_BLACKLIST,
  predicate: (action) => ACTIONS_TO_SYNC.includes(action.type),
  broadcastChannelOption: { type: 'localstorage' }
};

const createMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }).concat(createStateSyncMiddleware(stateSyncConfig));

// Store creation
const store = configureStore({
  reducer: rootReducer,
  middleware: createMiddleware
});

initMessageListener(store);

const persister = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { dispatch, persister, store, useDispatch, useSelector };
