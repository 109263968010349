// third-party
import { FormattedMessage } from 'react-intl';
// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconHome } from '@tabler/icons';
import { useSelector } from 'store';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconHome
};

const Maison = () => {

    const { listeMaisons, comparateur } = useSelector((state) => state.menu);

    let children;
    if(comparateur.children.length > 0){
        children = [...listeMaisons, comparateur];
    }else{
        children = [...listeMaisons];
    }
    
    const maison = {
        id: 'pages-maisons',
        type: 'group',
        children: [
            {
                id: 'maisons',
                title: <FormattedMessage id="Maisons"/>,
                type: 'collapse',
                icon: icons.IconHome,
                children
            }
        ]
    };

    return maison;
}

export default Maison;
