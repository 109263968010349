import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Maison routes
const MaisonList = Loadable(lazy(() => import('views/pages/list-maison')));
const Maison = Loadable(lazy(() => import('views/pages/maison/maison')));
// Groupe routes
const GroupeList = Loadable(lazy(() => import('views/pages/list-groupe')));
const Groupe = Loadable(lazy(() => import('views/pages/groupe/groupe')));
// Carte routes
const Map = Loadable(lazy(() => import('views/pages/map/map')));
// Comparateur MR
const Comparateur = Loadable(lazy(() => import('views/pages/comparateurMR/comparateur')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Map />
        },
        {
            path: '/maison-list', // TBC if rm this path and compo
            element: <MaisonList />
        },
        {
            path: '/maison/:id',
            element: <Maison />
        },
        // For display "Tarifs" tab directly
        {
            path: '/maison/:id/:tab',
            element: <Maison />
        },
        {
            path: '/groupe-list',  // TBC if rm this path and compo
            element: <GroupeList />
        },
        {
            path: '/groupe/:id',
            element: <Groupe />
        },
        {
            path: '/map',
            element: <Map />
        },
        // For MR ID selected from external URL
        {
            path: '/map/:id',
            element: <Map />
        },
        {
            path: '/comparateur/:ids',
            element: <Comparateur />
        }
    ]
};

export default MainRoutes;
