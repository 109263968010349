import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// third-party
import { IntlProvider } from 'react-intl';
import useConfig from 'hooks/useConfig';

// load locales files
const loadLocaleData = async (locale) => {
    switch (locale) {
        case 'fr': {
            const translations = await import('utils/locales/fr.json');
            return translations; 
        }
        case 'de':{
            const translations = await import('utils/locales/de.json');
            return translations; 
        }            
        case 'es':{
            const translations = await import('utils/locales/es.json');
            return translations; 
        }
        default: {
            const translations = await import('utils/locales/en.json');
            return translations;
        }
    }
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
    const { locale } = useConfig();
    const [messages, setMessages] = useState();

    useEffect(() => {
        loadLocaleData(locale).then((d) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={locale} defaultLocale="fr" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
